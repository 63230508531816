<template>
  <DashboardTemplate v-loading="loading">
    <div class="pd-3" v-if="data" id="credit-detail">
      <div class="mg-b-5">
        <span @click="$router.push('/result')" class="cr-pointer text-grey"
          >{{
            $route.params.type == "project"
              ? "โครงการส่งเสริมธุรกิจ"
              : $route.params.type == "insurance"
              ? "ประกันการส่งออก"
              : "สินเชื่อ"
          }}
        </span>
        <i class="fas el-icon-arrow-right text-grey"></i>
        {{
          $route.params.type == "project"
            ? data.projectName
            : $route.params.type == "insurance"
            ? data.insuranceName
            : data.creditName
        }}
      </div>
      <div class="mg-b-5">
        <img
          src="@/assets/project.png"
          class="w-100 rounded-sm"
          alt="โครงการ"
          v-if="$route.params.type == 'project'"
        />
        <img
          src="@/assets/credit-insurance.png"
          class="w-100 rounded-sm"
          alt="credit-insurance"
          v-else
        />
      </div>
      <el-row :gutter="20" type="flex" class="flex-warp content-editor">
        <el-col :md="6" class="mg-b-5">
          <img
            v-if="image"
            :src="`${imgUrl}${image}`"
            @error="require('../assets/exim-logo.jpg')"
            alt="EXIM Bank"
            class="mw-100 rounded-sm"
          />
          <img
            v-else
            src="@/assets/exim-logo.jpg"
            alt="EXIM Bank"
            class="mw-100 rounded-sm"
          />
        </el-col>
        <el-col
          :md="18"
          class="mg-b-5"
          v-if="$route.params.type == 'project'"
          id="credit-detail"
        >
          <div class="bordered border-primary pd-5 inside rounded-sm">
            <div>
              <p class="mg-t-0 title">{{ data.projectName }}</p>
              <p class="font-24 color-00 mg-b-0">รายละเอียดโครงการ</p>
              <div class="output ql-snow mg-b-6">
                <div class="ql-editor" v-html="data.projectDescription"></div>
              </div>
            </div>
            <div class="time-out-detail">
              <el-row>
                <el-col :sm="24" :lg="24" :xl="24" class="font-18">
                  <i class="fas fa-calendar-alt mg-r-7 mg-b-5"></i
                  ><span class="font-weight-bold">วันที่เปิดรับสมัคร </span
                  >{{ data.registerStart | dateNormal }} -
                  {{ data.registerEnd | dateNormal }}
                </el-col>
                <el-col :sm="24" :lg="24" :xl="24" class="font-18">
                  <i class="fas fa-calendar-alt mg-r-7 mg-b-5"></i
                  ><span class="font-weight-bold">วันที่จัดกิจกรรม </span
                  >{{ data.eventDate | dateNormal }} -
                  {{ data.eventDateEnd | dateNormal }}
                </el-col>
              </el-row>
              <!-- <div class="mg-b-5">
                <span
                  ><i class="fas fa-calendar-alt mg-r-7"></i>เปิดรับสมัคร
                  {{ data.registerStart | dateNormal }} -
                  {{ data.registerEnd | dateNormal }}</span
                ><span class="mg-l-3"
                  ><i class="fas fa-calendar-alt mg-r-7"></i>จัดกิจกรรม
                  {{ data.eventDate | dateNormal }} -
                  {{ data.eventDateEnd | dateNormal }}
                </span>
              </div> -->
              <div
                class="time-out-detail mg-b-5 mg-t-0"
                v-if="data.registerUrl"
              >
                <i class="fas fa-link mg-r-7"></i>
                <span class="font-weight-bold">ลิงก์สมัครโครงการ: </span>
                <a
                  :href="returnUrl(data.registerUrl)"
                  target="_blank"
                  style="color: #034ea2"
                >
                  {{ returnUrl(data.registerUrl) }}
                </a>
              </div>
              <div class="mg-b-5">
                <i class="fas fa-map-marker-alt mg-r-7"></i
                ><span class="font-weight-bold">สถานที่จัดกิจกรรม </span>
                {{ data.eventLocation }}
              </div>
              <div class="time-out-detail mg-b-5" v-if="data.ownerName">
                <i class="fas fa-thumbtack mg-r-7"></i>
                <span class="font-weight-bold">เจ้าของโครงการ </span>
                {{ data.ownerName }}
              </div>

              <!-- <div>
                <i class="fas fa-thumbtack mg-r-7"></i> เจ้าของโครงการ วัด
              </div> -->
              <div class="text-center" v-if="data">
                <div
                  class="btn-interest rounded-sm"
                  :class="{ active: data.interest }"
                  @click="
                    disableBtn
                      ? null
                      : !otherProduct &&
                        (type == 'insurance' || type == 'credit')
                      ? (modalContact = true)
                      : !data.interest && type == 'project'
                      ? ((modalLink = true),
                        (infoSuccess.projectName = data.projectName))
                      : !data.interest && type == 'project'
                      ? ((modalLink = true),
                        (infoSuccess.projectName = data.projectName))
                      : alertText()
                  "
                >
                  <i class="fas fa-heart font-10"></i>
                  {{ type == "project" ? "สมัคร" : "สนใจ" }}
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :md="18"
          class="mg-b-5"
          v-else-if="$route.params.type == 'insurance'"
        >
          <div class="bordered h-100 border-primary rounded-sm">
            <el-tabs type="card" class="tab-card-sm">
              <!-- <el-tab-pane label="1">
                <span slot="label"
                  ><p class="font-20">วันที่เริ่ม-วันที่สิ้นสุด</p>
                  <p class="font-20">การให้ประกัน</p></span
                >
                <div class="inside">
                  <h5>วันที่เริ่ม - วันที่สิ้นสุดการให้ประกัน</h5>
                  <div class="output ql-snow">
                    <div class="ql-editor">
                      {{ data.insuranceStart | dateNormal }} -
                      {{ data.insuranceEnd | dateNormal }}
                    </div>
                  </div>
                </div>
              </el-tab-pane> -->
              <el-tab-pane label="2" v-if="data.statusOne">
                <span slot="label"
                  ><p class="font-20">{{ data.titleOne }}</p></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleOne }}</h5> -->
                  <div class="output ql-snow">
                    <div
                      class="ql-editor"
                      v-html="data.insuranceOverview"
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="3" v-if="data.statusTwo">
                <span slot="label"
                  ><p class="font-20">{{ data.titleTwo }}</p></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleTwo }}</h5> -->
                  <div class="output ql-snow">
                    <div
                      class="ql-editor"
                      v-html="data.insuranceCondition"
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="4" v-if="data.statusThree">
                <span slot="label"
                  ><h5>{{ data.titleThree }}</h5></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleThree }}</h5> -->
                  <div class="output ql-snow">
                    <div class="ql-editor" v-html="data.protectedRisk"></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="5" v-if="data.statusFour">
                <span slot="label"
                  ><h5>{{ data.titleFour }}</h5></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleFour }}</h5> -->
                  <div class="output ql-snow">
                    <div class="ql-editor" v-html="data.converageRate"></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="6" v-if="data.statusFive">
                <span slot="label" class="font-20">{{ data.titleFive }}</span>
                <div class="inside">
                  <!-- <h5>{{ data.titleFive }}</h5> -->
                  <div class="output ql-snow">
                    <div class="ql-editor" v-html="data.insuranceCost"></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="7" v-if="data.statusSix">
                <span slot="label"
                  ><h5>{{ data.titleSix }}</h5>
                </span>
                <div class="inside">
                  <!-- <h5>{{ data.titleSix }}</h5> -->
                  <div class="output ql-snow">
                    <div
                      class="ql-editor"
                      v-html="data.insuranceClaimPay"
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="8" v-if="data.statusSeven">
                <span slot="label"
                  ><h5>{{ data.titleSeven }}</h5></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleSeven }}</h5> -->
                  <div class="output ql-snow">
                    <div
                      class="ql-editor"
                      v-html="data.serviceRequestProcess"
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="9" v-if="data.statusEight">
                <span slot="label"
                  ><h5>{{ data.titleEight }}</h5></span
                >
                <div class="inside">
                  <!-- <h5>{{ data.titleEight }}</h5> -->
                  <div class="output ql-snow">
                    <div class="ql-editor" v-html="data.specialCondition"></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="10" v-if="data.statusNine">
                <span slot="label">
                  <p>{{ data.titleNine }}</p>
                </span>
                <div class="inside">
                  <!-- <h5>{{data.titleNine }}</h5> -->
                  <div class="output ql-snow">
                    <div class="ql-editor" v-html="data.detailsNine"></div>
                  </div>
                </div>
              </el-tab-pane>
              <div class="text-center" v-if="data">
                <div
                  class="btn-interest rounded-sm"
                  :class="{ active: data.interest }"
                  @click="
                    disableBtn
                      ? null
                      : !otherProduct &&
                        (type == 'insurance' || type == 'credit')
                      ? (modalContact = true)
                      : !data.interest &&
                        type == 'project' &&
                        data.registerType == 1
                      ? ((modalContact = true),
                        (infoSuccess.projectName = data.projectName))
                      : !data.interest &&
                        type == 'project' &&
                        data.registerType == 2
                      ? ((modalLink = true),
                        (infoSuccess.projectName = data.projectName))
                      : alertText()
                  "
                >
                  <i class="fas fa-heart font-10"></i>
                  {{ type == "project" ? "สมัคร" : "สนใจ" }}
                </div>
              </div>
            </el-tabs>
          </div>
        </el-col>
        <el-col :md="18" class="mg-b-5" v-else>
          <div class="bordered h-100 border-primary rounded-sm">
            <el-tabs type="card" class="tab-card-sm">
              <el-tab-pane label="1" v-if="data.statusOne">
                <span slot="label">
                  <div>
                    {{ data.titleOne }}
                  </div>
                </span>
                <div class="inside">
                  <!-- <h5>
                    {{ data.titleOne }}
                  </h5> -->
                  <p v-html="data.attributeProductService"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="2" v-if="data.statusTwo">
                <span slot="label">
                  <p class="font-20">{{ data.titleTwo }}</p>
                </span>
                <div class="inside">
                  <!-- <h5>
                    {{ data.titleTwo }}
                  </h5> -->
                  <p v-html="data.taxOrIncrease"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="3" v-if="data.statusThree">
                <span slot="label" class="font-20"> {{ data.titleThree }}</span>
                <div class="inside">
                  <h5>
                    {{ data.titleThree }}
                  </h5>
                  <p v-html="data.limitMaximum"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="4" v-if="data.statusFour">
                <span slot="label" class="font-20"> {{ data.titleFour }}</span>
                <div class="inside">
                  <!-- <h5>
                    {{ data.titleFour }}
                  </h5> -->
                  <p v-html="data.guarantee"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="5" v-if="data.statusFive">
                <span slot="label" class="font-20"> {{ data.titleFive }}</span>

                <div class="inside">
                  <!-- <h5>
                    {{ data.titleFive }}
                  </h5> -->
                  <p v-html="data.property"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="6" v-if="data.statusSix">
                <span slot="label" class="font-20"> {{ data.titleSix }}</span>

                <div class="inside">
                  <!-- <h5>
                    {{ data.titleSix }}
                  </h5> -->
                  <p v-html="data.condition"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="7" v-if="data.statusSeven">
                <span slot="label" class="font-20"> {{ data.titleSeven }}</span>
                <div class="inside">
                  <!-- <h5>{{ data.titleSeven }}</h5> -->
                  <p v-html="data.description"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="8" v-if="data.statusEight">
                <span slot="label" class="font-20"> {{ data.titleEight }}</span>
                <div class="inside">
                  <!-- <h5>{{ data.titleEight }}</h5> -->
                  <p v-html="data.detailsEight"></p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="9" v-if="data.statusNine">
                <span slot="label" class="font-20"> {{ data.titleNine }}</span>
                <div class="inside">
                  <!-- <h5>{{ data.titleNine }}</h5> -->
                  <p v-html="data.detailsNine"></p>
                </div>
              </el-tab-pane>
              <div class="text-center" v-if="data">
                <div
                  class="btn-interest rounded-sm"
                  :class="{ active: data.interest }"
                  @click="
                    disableBtn
                      ? null
                      : !otherProduct &&
                        (type == 'insurance' || type == 'credit')
                      ? (modalContact = true)
                      : !data.interest &&
                        type == 'project' &&
                        data.registerType == 1
                      ? ((modalContact = true),
                        (infoSuccess.projectName = data.projectName))
                      : !data.interest &&
                        type == 'project' &&
                        data.registerType == 2
                      ? ((modalLink = true),
                        (infoSuccess.projectName = data.projectName))
                      : alertText()
                  "
                >
                  <i class="fas fa-heart font-10"></i>
                  {{ type == "project" ? "สมัคร" : "สนใจ" }}
                </div>
              </div>
            </el-tabs>
          </div>
        </el-col>
        <el-col>
          <!-- {{ otherProduct }}
          {{ !data.interest && (type == "insurance" || type == "credit") }}--{{
            !data.interest && type == "project" && data.registerType == 1
          }}--{{
            !data.interest && type == "project" && data.registerType == 2
          }} -->
          <!-- <pre>
            {{ data }}
          </pre> -->
          <!-- <pre>
            {{ dataCfoe }}
          </pre> -->
        </el-col>
      </el-row>
    </div>
    <el-dialog
    @open="$store.commit('SET_BRANCH')"
      :visible.sync="modalContact"
      :destroy-on-close="true"
      @close="resetfield()"
      width="45%"
    >
      <div v-if="type == 'project'">
        <h4 class="mg-t-0 mg-b-6">
          บุคคลที่ท่านต้องการให้สมัครเข้าร่วมโครงการ
        </h4>
        <h4 class="mg-t-0">
          {{ infoSuccess.projectName }}
        </h4>
      </div>
      <h4 class="mg-t-0" v-else>
        บุคคลที่ท่านต้องการให้ EXIM Bank
        ติดต่อกลับเพื่อนำเสนอรายละเอียดผลิตภัณฑ์เพิ่มเติม
      </h4>
      <el-form
        label-position="top"
        :rules="rules"
        :model="ruleForm"
        ref="ruleForm"
        class="text-left"
      >
        <el-form-item prop="projectType" v-if="type == 'project'">
          <el-checkbox-group v-model="ruleForm.projectType">
            <div>
              <el-checkbox label="self"
                >คุณ{{ user.name }} {{ user.surname }}</el-checkbox
              >
            </div>
            <div>
              <el-checkbox label="etc">ติดต่อกลับบุคคลอื่น</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="person" v-if="type != 'project'">
          <div>
            <el-radio v-model="ruleForm.person" label="self"
              >คุณ{{ user.name }} {{ user.surname }}</el-radio
            >
          </div>
          <div class="mg-t-7">
            <el-radio v-model="ruleForm.person" label="etc"
              >ติดต่อกลับบุคคลอื่น
            </el-radio>
          </div>
        </el-form-item>
        <div
          v-if="
            (type != 'project' && ruleForm.person == 'etc') ||
            (type == 'project' && checkType)
          "
          class="text-left"
        >
          <el-row :gutter="20">
            <el-col :sm="12" :offset="0">
              <el-form-item label="ชื่อ" prop="name">
                <el-input v-model="ruleForm.name" placeholder="ชื่อ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="นามสกุล" prop="surname">
                <el-input
                  v-model="ruleForm.surname"
                  placeholder="นามสกุล"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="เบอร์โทรศัพท์" prop="mobilephone">
                <el-input
                  v-mask="'##########'"
                  v-model="ruleForm.mobilephone"
                  placeholder="เบอร์โทรศัพท์"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :offset="0">
              <el-form-item label="อีเมล" prop="email">
                <el-input
                  type="email"
                  v-model="ruleForm.email"
                  placeholder="อีเมล"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="type == 'credit'">
          <el-divider></el-divider>
        </div>
        <div v-if="type == 'credit'">
          <el-row>
            <el-col :lg="16">
              <el-form-item
                label="สาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ"
                prop="branch"
              >
                <el-select
                  class="w-100"
                  v-model="ruleForm.branch"
                  placeholder="เลือกสาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ"
                >
                  <el-option
                    v-for="(data, index) in branchOption"
                    :key="index"
                    :label="data.label"
                    :value="data.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="text-right mg-t-1">
          <div class="is-flex js-end ai-center mg-t-1">
            <a
              @click="modalContact = false"
              class="text-grey-light font-18 mg-r-1"
              >ยกเลิก</a
            >
            <div>
              <el-button
                id="saveData"
                type="primary"
                class="wid180"
                :loading="btnLoading"
                @click="sendData()"
                >ส่งข้อมูล</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="modalSuccess"
      :destroy-on-close="true"
      width="40%"
      class="text-center"
    >
      <svg
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
      <div v-if="type == 'credit' || type == 'insurance'">
        <h2 class="text-success mg-y-5">
          EXIM Bank ได้รับข้อมูลของท่านแล้ว และจะติดต่อกลับท่านโดยเร็ว
        </h2>
        <h4 class="mg-t-5 mg-b-1 pd-b-1">
          ขอขอบคุณที่ให้ความสนใจผลิตภัณฑ์ของธนาคาร
        </h4>
        <!-- <h4 class="mg-y-5 text-dark">
        คุณ{{ infoSuccess.name }} {{ infoSuccess.surname }}
      </h4> -->
      </div>
      <div v-if="type == 'project'">
        <h2 class="text-success mg-y-5">ท่านได้สมัครเข้าร่วม</h2>
        <h4 class="mg-y-5">{{ infoSuccess.projectName }}</h4>
        <h4 class="text-success mg-y-5">เรียบร้อยแล้ว</h4>
        <h4 class="mg-y-1 font-20">
          ขอขอบคุณที่ให้ความสนใจโครงการของ EXIM Bank
        </h4>
      </div>

      <div class="text-center mg-t-1">
        <router-link to="/" class="text-primary"
          >กลับหน้าแบบประเมิน</router-link
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalLink"
      v-if="modalLink"
      :destroy-on-close="true"
      width="40%"
    >
      <h5 class="text-dark mg-b-0 mg-t-0 font-22">
        ท่านสามารถสมัครเข้าร่วมโครงการ
      </h5>
      <h5 class="text-dark mg-t-7 font-22">
        {{ infoSuccess.projectName }} ได้ที่
      </h5>
      <a
        :href="data.registerUrl"
        @click="linkInterest()"
        target="_blank"
        class="link-interest d-flex"
        ><div class="is-flex ai-center">
          <img
            src="@/assets/icon-link.png"
            class="mg-r-6"
            alt="ลิงก์สมัครโครงการ"
          />{{ data.registerUrl }}
        </div>
      </a>
      <div class="text-right mg-t-1">
        <div class="is-flex js-end ai-center mg-t-1">
          <!-- <a @click="modalLink = false" class="text-grey-light font-18 mg-r-1"
            >ยกเลิก</a
          >
          <div>
            <el-button
              id="saveData"
              type="primary"
              :loading="btnLoading"
              @click="linkInterest()"
              >สนใจ</el-button
            >
          </div> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="modalAlert"
      :destroy-on-close="true"
      width="40%"
      class="text-center"
    >
      <div>
        <h2 class="mg-y-5">
          ท่านได้แจ้งความสนใจ "{{
            $route.params.type == "project"
              ? data.projectName
              : $route.params.type == "insurance"
              ? data.insuranceName
              : data.creditName
          }}" ไว้เรียบร้อยแล้ว
        </h2>
        <h4 class="mg-t-1">หากท่านต้องการสอบถามรายละเอียดเพิ่มเติม</h4>
        <h4 class="mg-t-5 mg-b-1 pd-b-1">กรุณาติดต่อเบอร์ 02 169 9999</h4>
      </div>
    </el-dialog>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import jwt from "jsonwebtoken";
import axios from "axios";
import moment from "moment";
export default {
  components: {
    DashboardTemplate
  },
  data() {
    return {
      disableBtn: false,
      loading: false,
      dataCredit: null,
      data: null,
      image: null,
      imgUrl: process.env.VUE_APP_IMAGE_CFOE,
      modalSuccess: false,
      modalContact: false,
      dataCfoe: null,
      modalAlert: false,
      ruleForm: {
        projectType: [],
        person: "",
        branch: "",
        juristicId: null,
        productId: null,
        type: null,
        name: "",
        surname: "",
        email: "",
        mobilephone: "",
        companyId: null
      },
      rules: {
        projectType: [
          {
            required: true,
            message: "กรุณาเลือกบุุคคลติดต่อกลับ",
            trigger: "change"
          }
        ],
        person: [
          {
            required: true,
            message: "กรุณาเลือกบุุคคลติดต่อกลับ",
            trigger: "change"
          }
        ],
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อ",
            trigger: "change"
          }
        ],
        surname: [
          {
            required: true,
            message: "กรุณากรอกนามสกุล",
            trigger: "change"
          }
        ],
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur"
          },
          {
            message: "รูปแบบอีเมลไม่ถูกต้อง",
            trigger: "blur",
            type: "email"
          }
        ],
        mobilephone: [
          {
            required: true,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือ",
            trigger: "blur"
          },
          {
            min: 10,
            max: 13,
            message: "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบ",
            trigger: "blur"
          }
        ],
        branch: [
          {
            required: true,
            message: "กรุณาเลือกสาขาที่ท่านสนใจให้เจ้าหน้าที่ติดต่อกลับ",
            trigger: "change"
          }
        ]
      },
      infoSuccess: {
        name: "",
        surname: "",
        projectName: ""
      },
      btnLoading: false,
      productName: "",
      otherProduct: true,
      modalLink: false,
      userData: null,
      type: this.$route.params.type,
      verifyToken: null,
      url: process.env.VUE_APP_BASE_URL.replace("api/", "")
    };
  },
  computed: {
    checkType() {
      let index = this.ruleForm.projectType.findIndex(a => a == "etc");
      return index > -1 ? true : false;
    },
    branchOption() {
      return this.$store.state.branch;
    }
  },
  async mounted() {
    this.verifyToken = jwt.verify(this.$route.params.id, "ABCDEFG123456789");
    if (this.verifyToken.userId != this.user.id) {
      this.$router.push("/").catch(() => {});
    }
    this.otherProduct = this.verifyToken.allow;
    await this.getData();
    this.fetchResult();
  },
  methods: {
    alertText() {
      if (this.type != "project") {
        this.modalAlert = true;
        //let text = `ท่านได้แจ้งความสนใจ ${this.productName} ไว้เรียบร้อยแล้ว และธนาคารจะรีบติดต่อกลับท่านโดยเร็ว หากท่านต้องการสอบถามรายละเอียดเพิ่มเติม กรุณาติดต่อเบอร์ 02 037 6099`;
        // if (this.$route.params.type == "credit") {
        //   text = "ไม่สามารถกดสนใจสินเชื่อประเภทเดียวกันได้มากกว่า 1 สินเชื่อ";
        // }
        // if (!this.data.interest) {
        //   this.$message.error(text);
        // }
      }
    },
    resetfield() {
      this.ruleForm = {
        projectType: [],
        person: "",
        branch: "",
        juristicId: null,
        productId: null,
        type: null,
        name: "",
        surname: "",
        email: "",
        mobilephone: "",
        companyId: null
      };
    },
    async fetchResult() {
      let obj = {
        userId: this.user.id
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post(`recommend/result`, obj)
        .then(async res => {
          if (res.data.success) {
            let product = JSON.parse(
              JSON.stringify(res.data.resCfoe.products[this.type])
            );
            if (this.type == "insurance") {
              let find = product.findIndex(a => a.interest);
              if (find == -1) {
                this.otherProduct = false;
              } else {
                this.otherProduct = true;
                this.productName = product[find].insuranceName;
              }
            } else if (this.type == "credit") {
              let find = product.findIndex(
                a => a.interestType == this.data.interestType && a.interest
              );
              if (find == -1) {
                this.otherProduct = false;
              } else {
                this.otherProduct = true;
                this.productName = product[find].creditName;
              }
            } else {
              this.otherProduct = false;
            }
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          }
        })
        .finally(() => {
          this.disableBtn = false;
        });
    },
    sendData() {
      this.disableBtn = true;
      let obj;
      this.btnLoading = true;
      if (this.type == "project") {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: Number(this.verifyToken.productId),
          type: this.type,
          nameDefault: this.user.name,
          surnameDefault: this.user.surname,
          mobileDefault: this.user.mobilephone,
          emailDefault: this.user.email,
          name: this.ruleForm.name,
          surname: this.ruleForm.surname,
          email: this.ruleForm.email,
          mobilephone: this.ruleForm.mobilephone,
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
      } else if (
        this.ruleForm.person == "self" ||
        this.ruleForm.person == null
      ) {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: Number(this.verifyToken.productId),
          type: this.type,
          nameDefault: this.user.name,
          surnameDefault: this.user.surname,
          mobileDefault: this.user.mobilephone,
          emailDefault: this.user.email,
          name: "",
          surname: "",
          mobilephone: "",
          email: "",
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
      } else {
        obj = {
          juristicId: this.user.corporateNumberInterest,
          productId: Number(this.verifyToken.productId),
          type: this.type,
          nameDefault: "",
          surnameDefault: "",
          mobileDefault: "",
          emailDefault: "",
          name: this.ruleForm.name,
          surname: this.ruleForm.surname,
          mobilephone: this.ruleForm.mobilephone,
          email: this.ruleForm.email,
          branch: this.ruleForm.branch,
          companyId: this.user.companyId
        };
      }

      this.$refs.ruleForm.validate(async valid => {
        if ( valid )
        {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post("/interest/product", obj)
            .then(res => {
              if (res.data.success) {
                this.modalContact = false;
                this.modalSuccess = true;
                this.infoSuccess.name = obj.name;
                this.infoSuccess.surname = obj.surname;
                this.resetfield();
                this.getData();
                this.fetchResult();
              } else {
                this.alertCatchError(res.data.data);
              }
              this.btnLoading = false;
            })
            .catch(e => {
              this.btnLoading = false;
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
            });
        } else {
          this.btnLoading = false;
        }
      });
    },
    linkInterest() {
      this.disableBtn = true;
      this.btnLoading = true;
      let obj = {
        juristicId: this.user.corporateNumberInterest,
        productId: Number(this.verifyToken.productId),
        type: this.$route.params.type,
        nameDefault: this.user.name,
        surnameDefault: this.user.surname,
        mobileDefault: this.user.mobilephone,
        emailDefault: this.user.email,
        name: "",
        surname: "",
        mobilephone: "",
        email: "",
        branch: this.ruleForm.branch,
        companyId: this.user.companyId
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post("/interest/product", obj)
        .then(res => {
          if (res.data.success) {
            this.modalSuccess = true;
            this.modalLink = false;
            this.infoSuccess.name = obj.name;
            this.infoSuccess.surname = obj.surname;
            this.resetfield();
            this.getData();
            this.fetchResult();
          } else {
            this.alertCatchError(res.data.data);
          }
          this.btnLoading = false;
        })
        .catch(e => {
          this.btnLoading = false;
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    returnUrl(url) {
      const myArr = url.split("http://");
      const myArrHttpsmyArr = url.split("https://");
      if (myArr.length > 1) {
        return "http://" + myArr[1];
      } else if (myArrHttpsmyArr.length > 1) {
        return "https://" + myArrHttpsmyArr[1];
      } else {
        return "http://" + url;
      }
    },
    async getData() {
      let obj = {
        productId: this.verifyToken.productId,
        type: this.verifyToken.type,
        companyId: this.user.companyId
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      await HTTP.post("product/detail", obj)
        .then(res => {
          if (res.data.success) {
            let now = new Date();
            let dateNow = moment(now).format("YYYY-MM-DD");
            if (this.verifyToken.type == "insurance") {
              let dateEnd = moment(res.data.obj.insuranceEnd).format(
                "YYYY-MM-DD"
              );
              if (dateNow > dateEnd) {
                this.alertCatchError("ประกันนี้หมดอายุแล้ว");
                this.$router.push("/").catch(() => {});
              } else if (!res.data.obj.hiddenInsurance) {
                this.$router.push("/").catch(() => {});
              }
            }

            if (this.verifyToken.type == "credit") {
              let dateEnd = moment(res.data.obj.creditEnd).format("YYYY-MM-DD");
              if (dateNow > dateEnd) {
                this.alertCatchError("สินเชื่อนี้หมดอายุแล้ว");
                this.$router.push("/").catch(() => {});
              } else if (!res.data.obj.hiddenCredit) {
                this.$router.push("/").catch(() => {});
              }
            }
            if (this.verifyToken.type == "project") {
              let dateEnd = moment(res.data.obj.eventDateEnd).format(
                "YYYY-MM-DD"
              );
              if (dateNow > dateEnd) {
                this.alertCatchError("โครงการนี้หมดอายุแล้ว");
                this.$router.push("/").catch(() => {});
              } else if (!res.data.obj.hiddenProject) {
                this.$router.push("/").catch(() => {});
              }
            }

            this.data = res.data.obj;
            this.data.interest = res.data.interest;
            this.image = res.data.image;
          } else {
            this.alertCatchError(res.data.data);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("getData error", e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
